import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as blanknDnebENFWFMeta } from "/app/pages/blank.vue?macro=true";
import { default as indexP99MOssB9pMeta } from "/app/pages/booking/admin/index.vue?macro=true";
import { default as indexZtw0HnvlUYMeta } from "/app/pages/booking/client/[id]/index.vue?macro=true";
import { default as installment6JwcHWLdfNMeta } from "/app/pages/booking/client/[id]/installment.vue?macro=true";
import { default as mortgagelA6EAbI90WMeta } from "/app/pages/booking/client/[id]/mortgage.vue?macro=true";
import { default as summary9aNKYgFQFxMeta } from "/app/pages/booking/client/[id]/summary.vue?macro=true";
import { default as indexpa09IIqHJMMeta } from "/app/pages/booking/client/index.vue?macro=true";
import { default as indexb7wgDeA968Meta } from "/app/pages/booking/index.vue?macro=true";
import { default as indexOcsRkjobiDMeta } from "/app/pages/categories/index.vue?macro=true";
import { default as confirmedRQogapHQrZMeta } from "/app/pages/email/confirmed.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as indexti6rLsGQMFMeta } from "/app/pages/mortgage/[mortgageApplicationId]/index.vue?macro=true";
import { default as index3oaXnK6hSYMeta } from "/app/pages/mortgage/create/index.vue?macro=true";
import { default as index2P9qQZsNGZMeta } from "/app/pages/mortgage/login/index.vue?macro=true";
import { default as _91partnerId_93lUjyUgGzEeMeta } from "/app/pages/partners/[partnerId].vue?macro=true";
import { default as indexLG3Wtz8U6fMeta } from "/app/pages/partners/index.vue?macro=true";
import { default as index1skj8KXPwUMeta } from "/app/pages/realty/index.vue?macro=true";
import { default as _91applicationId_93XfgVAnVQweMeta } from "/app/pages/realty/registration/[applicationId].vue?macro=true";
import { default as indexaMKOe7ofeAMeta } from "/app/pages/realty/registration/index.vue?macro=true";
import { default as loginGSSk0IxjgVMeta } from "/app/pages/realty/registration/login.vue?macro=true";
import { default as acceptancesPDRtXtRd4TMeta } from "/app/pages/settlement/admin/acceptances.vue?macro=true";
import { default as indexgVAKJ8kSeEMeta } from "/app/pages/settlement/admin/index.vue?macro=true";
import { default as monitoringQZHiDKwuKQMeta } from "/app/pages/settlement/admin/monitoring.vue?macro=true";
import { default as _91id_93L8tZjl9wzJMeta } from "/app/pages/settlement/client/acceptance/[id].vue?macro=true";
import { default as index22OY8hMsdNMeta } from "/app/pages/settlement/client/acceptance/index.vue?macro=true";
import { default as indexjVMBt5rODNMeta } from "/app/pages/settlement/client/index.vue?macro=true";
import { default as indexlvjtNKiBPPMeta } from "/app/pages/settlement/index.vue?macro=true";
import { default as _91userId_93mOuoHyVCfrMeta } from "/app/pages/users/[userId].vue?macro=true";
import { default as indexnq54o81oozMeta } from "/app/pages/users/index.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "blank",
    path: "/blank",
    meta: blanknDnebENFWFMeta || {},
    component: () => import("/app/pages/blank.vue").then(m => m.default || m)
  },
  {
    name: "booking-admin",
    path: "/booking/admin",
    meta: indexP99MOssB9pMeta || {},
    component: () => import("/app/pages/booking/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "booking-client-id",
    path: "/booking/client/:id()",
    meta: indexZtw0HnvlUYMeta || {},
    component: () => import("/app/pages/booking/client/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "booking-client-id-installment",
    path: "/booking/client/:id()/installment",
    meta: installment6JwcHWLdfNMeta || {},
    component: () => import("/app/pages/booking/client/[id]/installment.vue").then(m => m.default || m)
  },
  {
    name: "booking-client-id-mortgage",
    path: "/booking/client/:id()/mortgage",
    meta: mortgagelA6EAbI90WMeta || {},
    component: () => import("/app/pages/booking/client/[id]/mortgage.vue").then(m => m.default || m)
  },
  {
    name: "booking-client-id-summary",
    path: "/booking/client/:id()/summary",
    meta: summary9aNKYgFQFxMeta || {},
    component: () => import("/app/pages/booking/client/[id]/summary.vue").then(m => m.default || m)
  },
  {
    name: "booking-client",
    path: "/booking/client",
    meta: indexpa09IIqHJMMeta || {},
    component: () => import("/app/pages/booking/client/index.vue").then(m => m.default || m)
  },
  {
    name: "booking",
    path: "/booking",
    meta: indexb7wgDeA968Meta || {},
    component: () => import("/app/pages/booking/index.vue").then(m => m.default || m)
  },
  {
    name: "categories",
    path: "/categories",
    meta: indexOcsRkjobiDMeta || {},
    component: () => import("/app/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "email-confirmed",
    path: "/email/confirmed",
    component: () => import("/app/pages/email/confirmed.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "mortgage-mortgageApplicationId",
    path: "/mortgage/:mortgageApplicationId()",
    meta: indexti6rLsGQMFMeta || {},
    component: () => import("/app/pages/mortgage/[mortgageApplicationId]/index.vue").then(m => m.default || m)
  },
  {
    name: "mortgage-create",
    path: "/mortgage/create",
    component: () => import("/app/pages/mortgage/create/index.vue").then(m => m.default || m)
  },
  {
    name: "mortgage-login",
    path: "/mortgage/login",
    meta: index2P9qQZsNGZMeta || {},
    component: () => import("/app/pages/mortgage/login/index.vue").then(m => m.default || m)
  },
  {
    name: "partners-partnerId",
    path: "/partners/:partnerId()",
    meta: _91partnerId_93lUjyUgGzEeMeta || {},
    component: () => import("/app/pages/partners/[partnerId].vue").then(m => m.default || m)
  },
  {
    name: "partners",
    path: "/partners",
    meta: indexLG3Wtz8U6fMeta || {},
    component: () => import("/app/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: "realty",
    path: "/realty",
    meta: index1skj8KXPwUMeta || {},
    component: () => import("/app/pages/realty/index.vue").then(m => m.default || m)
  },
  {
    name: "realty-registration-applicationId",
    path: "/realty/registration/:applicationId()",
    meta: _91applicationId_93XfgVAnVQweMeta || {},
    component: () => import("/app/pages/realty/registration/[applicationId].vue").then(m => m.default || m)
  },
  {
    name: "realty-registration",
    path: "/realty/registration",
    meta: indexaMKOe7ofeAMeta || {},
    component: () => import("/app/pages/realty/registration/index.vue").then(m => m.default || m)
  },
  {
    name: "realty-registration-login",
    path: "/realty/registration/login",
    meta: loginGSSk0IxjgVMeta || {},
    component: () => import("/app/pages/realty/registration/login.vue").then(m => m.default || m)
  },
  {
    name: "settlement-admin-acceptances",
    path: "/settlement/admin/acceptances",
    meta: acceptancesPDRtXtRd4TMeta || {},
    component: () => import("/app/pages/settlement/admin/acceptances.vue").then(m => m.default || m)
  },
  {
    name: "settlement-admin",
    path: "/settlement/admin",
    meta: indexgVAKJ8kSeEMeta || {},
    component: () => import("/app/pages/settlement/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "settlement-admin-monitoring",
    path: "/settlement/admin/monitoring",
    meta: monitoringQZHiDKwuKQMeta || {},
    component: () => import("/app/pages/settlement/admin/monitoring.vue").then(m => m.default || m)
  },
  {
    name: "settlement-client-acceptance-id",
    path: "/settlement/client/acceptance/:id()",
    meta: _91id_93L8tZjl9wzJMeta || {},
    component: () => import("/app/pages/settlement/client/acceptance/[id].vue").then(m => m.default || m)
  },
  {
    name: "settlement-client-acceptance",
    path: "/settlement/client/acceptance",
    meta: index22OY8hMsdNMeta || {},
    component: () => import("/app/pages/settlement/client/acceptance/index.vue").then(m => m.default || m)
  },
  {
    name: "settlement-client",
    path: "/settlement/client",
    meta: indexjVMBt5rODNMeta || {},
    component: () => import("/app/pages/settlement/client/index.vue").then(m => m.default || m)
  },
  {
    name: "settlement",
    path: "/settlement",
    meta: indexlvjtNKiBPPMeta || {},
    component: () => import("/app/pages/settlement/index.vue").then(m => m.default || m)
  },
  {
    name: "users-userId",
    path: "/users/:userId()",
    meta: _91userId_93mOuoHyVCfrMeta || {},
    component: () => import("/app/pages/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    meta: indexnq54o81oozMeta || {},
    component: () => import("/app/pages/users/index.vue").then(m => m.default || m)
  }
]