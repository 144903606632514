import { type FetchOptions } from 'ofetch';
import { useAuthStore } from '~/stores/auth/useAuthStore';
import { AuthTokenCookieName } from '~/types/consts/auth';
import { StatusCodes } from 'http-status-codes';
import { ROUTES } from '~/constants/routes';
import type { ErrorData } from "@frontend/ui-kit/src/types/error";

/**
 * Ответ ошибки API.
 */
class ApiResponseError implements ErrorData {
    /** Идентификатор трассировки. */
    traceId: string;
    /**Описание. */
    description: string;
    /** Количество записей на странице. */
    message: string;
    /** Пропускаемое количество записей. */
    statusCode: number;
    /** Пропускаемое количество записей. */
    statusMessage: string;
    /**URL */
    url: string | null;

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    constructor(response: any) {
        this.traceId = response._data.traceId;
        this.message = response._data.message;
        this.description = response._data.description;
        this.statusCode = response.status;
        this.statusMessage = response.statusMessage;
        this.url = response.url;
    }
}

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();
    const refreshTokenUrl = '/identify/token/refresh';

    /** Переопределяет запрос к API. */
    const api = $fetch.create({
        credentials: 'include',
        onRequest({ options }): void | Promise<void> {
            const userAuth = useCookie(AuthTokenCookieName);
            const token = userAuth.value;

            if (token) {
                setAuthorizationHeader(token, options);
            }
        },
        async onResponseError({ response }) {
            /** Если ответ - неавторизован, и это был запрос не на обновление токена, то пытаемся обновить */
            if (response.status === StatusCodes.UNAUTHORIZED && response.url.indexOf(refreshTokenUrl) < 0) {
                await refreshToken();
            } else {
                throw new ApiResponseError(response);
            }
        },
    });

    const refreshToken = async () : Promise<void> => {
        await $fetch(config.public.securityApiBaseUrl + refreshTokenUrl, {
            server: false,
            credentials: 'include',

            async onResponseError() : Promise<void> {
                const tokenCookie = useCookie(AuthTokenCookieName);
                tokenCookie.value = null;
                
                const store = useAuthStore();
                const isOperator = store.isOperator;
                store.logout();

                // Получаем текущий URL
                const currentUrl = window.location.href;
                // Создаем объект URL
                const url = new URL(currentUrl);
                if(url.pathname !== ROUTES.login
                    && url.pathname !== ROUTES.admin) {
                    // Получаем относительный путь
                    const relativePath = url.pathname + url.search; // добавляем search, если нужно
                    // Кодируем текущий URL
                    const encodedReturnUrl = encodeURIComponent(relativePath);
    
                    // Если токен протух кидаем на страницу логина ЛК.
                    const link = isOperator
                        ? config.public.adminLoginUrl
                        : config.public.clientLoginUrl;
                    await navigateTo(`${link}?return_url=${encodedReturnUrl}`);
                }
            },
        });
    }

    const setAuthorizationHeader = (token: string, options: FetchOptions) : void => {
        const authroizationHeaderName = 'Authorization';
        const authroizationString = `Bearer ${token}`;
        const headers = options.headers ||= {};
        if (Array.isArray(headers)) {
            headers.push([authroizationHeaderName, authroizationString]);
        } else if (headers instanceof Headers) {
            headers.set(authroizationHeaderName, authroizationString);
        } else {
            headers.Authorization = authroizationString;
        }
    };

    return {
        provide: {
            api
        }
    }
});