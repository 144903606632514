import { defineStore } from 'pinia';

export const useReferrerStore = defineStore('referrer', () => {
    const referrer = ref<string | undefined>(undefined);
    const _isChanged = ref(false);

    const setReferrer = (newValue: string) : void => {
        _isChanged.value = isReferrerChanged(newValue);
        referrer.value = newValue; // Сохраняем referer в store                            
    }

    const getReferrer = () : string | undefined => {
        return _isChanged.value ? referrer.value : undefined;
    }

    const isReferrerChanged = (newValue: string) => {
        // Если реферер отсутствует или равен новому значению, возвращаем false
        if (!referrer?.value || referrer.value === newValue) {
            return false;
        }
        // В противном случае, реферер изменился
        return true;
    };

    return {
        getReferrer,
        setReferrer
    }
});