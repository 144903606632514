/** Пол. */
export enum Sex {
    /** Мужчина */
    Man = 'Man',

    /** Женщина */
    Woman = 'Woman',

    /** Не указан */
    Undefined = 'Undefined',
}

/** Способ авторизации. */
export enum IdentifyType {
    /** По смс. */
    Sms = 'Sms',

    /** По паролю. */
    Password = 'Password'
}

// типы ролей
export const RoleTypes  = {
    Undefined: 'Undefined',                             // Неизвестный статус
    Administrator: 'Administrator',                     // Администратор
    Client: 'Client',                                   // Клиент
    SettlementAdministrator: 'SettlementAdministrator', // Администратор заселения
    SettlementManager: 'SettlementManager',             // Менеджер заселения
    BookingAdministrator: 'SettlementAdministrator',    // Администратор бронирования
    BookingManager: 'BookingManager',                   // Менеджер бронирования
    MarketingManager: 'MarketingManager'    // Младший менеджер отдела маркетинга
} as const

export type RoleType = typeof RoleTypes[keyof typeof RoleTypes];
