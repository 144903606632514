import { useAuthStore } from '~/stores/auth/useAuthStore';
import { useReferrerStore } from "~/stores/navigation/useReferrerStore";

export default defineNuxtRouteMiddleware(async () => {
    // токен на стороне клиента, поэтому серверную обработку пропускаем
    if(import.meta.server) {
        return;
    }
    
    // Сохраняем текущую страницу для возврата.
    const referrerStore = useReferrerStore();
    const referrer = document.location.pathname + document.location.search || '';
    referrerStore.setReferrer(referrer);
    
    // Инициализируем поля пользователя.
    const store = useAuthStore();
    await store.init();
})